<template>
    <div class="about-us">
        <h1>About Us</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc at aliquet.</p>
    </div>
</template>

<script>
export default {
    name: 'AboutUsPage',
};
</script>

<style scoped>
.about-us {
    padding: 20px;
}
.about-us h1 {
    font-size: 2em;
    color: #333;
}
.about-us p {
    font-size: 1em;
    color: #666;
}
</style>