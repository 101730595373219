import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {VueMetaPlugin} from 'vue-meta'

const app = createApp(App)

app.use(VueMetaPlugin);

app.use(router)

app.mount('#app')