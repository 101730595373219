<template>
   <div class="floatingContact">
     <ContactForm :modal="this.modal"></ContactForm>
   </div>
    <div class="carousel">
        <button class="carousel-button prev" @click="PrevImage">Prev Image</button>
        <div v-for="(image, index) in images" :key="index" class="slide" :class="{ active:index === currentImageIndex}" v-show="index === currentImageIndex">
            <img :src="image.src" :alt="image.alt">
        </div>
        <button class="carousel-button next" @click="NextImage">Next Image</button>
        <button class="carousel-button pause" @click="toggleAutoScroll">{{ isAutoScrolling ? 'Pause' : 'Play' }}</button>
    </div>
</template>


<script>
import ContactForm from '@/components/ContactForm.vue';
export default {
    name: 'PhotoCarousel',
    metaInfo: {
        title: 'Roofing Services | Homes Roofing Co. | Niles, MI',
        meta: [
        {
            name: 'Homes Photo Carousel Component',
            content: 'Get high-quality roofing services from Homes Roofing in Niles, Michigan. Our experienced team provides expert installation, repair, and maintenance services for residential and commercial properties. Contact us today for a free estimate.',
        },
        ],
    },
    data(){
        return{
            currentImageIndex: 0,
            isAutoScrolling: true,
            autoScrollInterval: null,
        }
    },
    mounted() {
    this.stopAutoScroll();
    },
    beforeUnmount() {
        this.stopAutoScroll();
    },
    props: {
            images: {
                type: Array,
                required: true
            }
        },
        components: {
            ContactForm
        },
    methods: {
        NextImage(){
            this.currentImageIndex++;
            if (this.currentImageIndex > this.images.length - 1) {
                this.currentImageIndex = 0;
            }
        },
        PrevImage(){
            this.currentImageIndex--
            if (this.currentImageIndex < 0) {
                this.currentImageIndex = this.images.length - 1;
            }
        },
        startAutoScroll() {
            this.autoScrollInterval = setInterval(this.NextImage, 3000); // Change images every 3 seconds
        },
        stopAutoScroll() {
            clearInterval(this.autoScrollInterval);
        },
        toggleAutoScroll() {
            this.isAutoScrolling = !this.isAutoScrolling;
            this.isAutoScrolling ? this.startAutoScroll() : this.stopAutoScroll();
        },
    },
}
</script>

<style>
.carousel {
  position: relative;
}
.carousel img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .carousel-button {
  background-color: #008CBA; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 24px; /* Some padding */
  cursor: pointer; /* Mouse pointer on hover */
  font-size: 16px; /* Change default font size */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transitions */
}

.carousel-button:hover {
  background-color: #006994; /* Darker blue on hover */
}

.carousel-button.prev {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.carousel-button.next {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.carousel-button.pause {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.PhotoCarousel img {
      width: 100%;
      height: 700px; /* Adjust as needed */
      object-fit: cover;
}

</style>