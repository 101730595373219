<template>
    <div>
        <!-- Your other code... -->
        <button @click="goToChat">Quick Chat</button>
    </div>
</template>

<script>
export default {
    name: 'QuickChat',
    methods: {
        goToChat() {
            this.$router.push('/chat');
        },
    },
};
</script>

<style scoped>
</style>