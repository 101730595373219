<template>
  <button class="contact-button" @click="openModal()">Request A Free Quote</button>
  <div id="modal" class="modal">
    <div class="modal-content"  data-scroll-index="7">
      <span class="close" @click="hardCloseModal()">&times;</span>
      <h2 v-if="!isSubmitted" class="contact-us-title"> Contact Us Today For A Free Quote!</h2>
      <h2 v-else class="contact-us-title"> Thank you, we will be in contact soon!</h2>
      <form v-if="!isSubmitted" id="form-data-collection" @submit.prevent="submitForm">
        <div class="form-groups">
          <div class="form-group">
            <label for="name"></label>
            <input type="text" id="name" name="name" v-model="name" required>
            <span class="floating-label">Name</span>
          </div>

          <div class="form-group">
            <label for="phone"></label>
            <input type="tel" id="phone" name="phone" v-model="phone" required>
            <span class="floating-label">Phone</span>
          </div>

          <div class="form-group">
            <label for="address"></label>
            <input type="text"  id="address" name="address" v-model="address" required>
            <span class="floating-label">Address</span>
          </div>

          <div class="form-group">
            <label for="city"></label>
            <input type="text"  id="city" name="city" v-model="city" required>
            <span class="floating-label">City</span>
          </div>

          <div class="form-group">
            <label for="zip-code"></label>
            <input type="text"  id="zip-code" name="zip-code" v-model="zipCode" required pattern="[0-9]{5}">
            <span class="floating-label">Zip</span>
          </div>

          <div class="form-group">
            <label for="email"></label>
            <input type="email" id="email" name="email" v-model="email" required>
            <span class="floating-label">Email</span>
          </div>
<!-- Maybe switch this to a radio button? -->
        </div>
        <div class="Selections">
          <h2 class="selector-label">How would you like to be contacted?</h2>
          <label for="preferences"></label>
          <select v-model="contactInfo" id="preferences" name="preferences">
            <option value="" disabled selected>Select one</option>
            <option value="Phone Call">Phone Call</option>
            <option value="Email">Email</option>
          </select>

          <h2 class="selector-label">Are you interested in financing?</h2>
          <label for="preferences"></label>
          <select v-model="financing" id="preferences" name="preferences">
            <option value="" disabled selected>Select one</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Not Sure">I Would Like More Information</option>
          </select>

          <label for="services"></label>
          <h2 class="selector-label">What Services are you interested in?</h2>
          <select v-model="services" id="services" name="services" class="dropdown">
            <option value="" disabled selected>Select one</option>
            <option value="Roof Replacement">Roof Replacement</option>
            <option value="Roof Repair">Roof Repair</option>
            <option value="Gutter Replacement">Gutter Replacement</option>
            <option value="Winter Services">Winter Services</option>
            <option value="Siding">Siding</option>
            <option value="Fascia & Soffit">Fasca & Soffit</option>
i          </select>
          
      </div>
      <button type="submit" class="Request" v-on:click="ThankYouFrom">Request Quote</button>
      
      </form>

    <div class="bottom-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 d-flex align-items-center">
                    <div class="contact-info">

                        <h2 class="contact-title">Prefer to Contact Us Directly?</h2>
                        <ul class="contact-info">
                            <li>
                              <div class="info-left">
                                  <i class="fas fa-mobile-alt"></i>
                              </div>
                              <div class="info-right">

                                <p>Phone: <a href="tel:269-262-5055">269-262-5055</a></p>
                              </div>
                            </li>
                            <li>
                              <div class="info-left">
                                  <i class="fas fa-at"></i>
                              </div>
                              <div class="info-right">
                                <p>Email: <a href="mailto:Homesroofingco@Gmail.com">Homesroofingco@Gmail.com</a></p>
                              </div>
                            </li>
                            <li>
                            </li>
                        </ul>
                    </div>
                </div>
          </div>
        </div>  
      </div>  
    </div>
  </div>

</template>

<script>

import emailjs from 'emailjs-com';

/* eslint-disable */
export default {
  name: 'ContactForm',
  metaInfo: {
        title: 'Roofing Services | Homes Roofing Co. | Niles, MI',
        meta: [
        {
            name: 'Homes Contact Us Form Component',
            content: 'Get high-quality roofing services from Homes Roofing in Niles, Michigan. Our experienced team provides expert installation, repair, and maintenance services for residential and commercial properties. Contact us today for a free estimate.',
        },
        ],
    },
  data() {
    return {
      name: '',
      phone: '',
      address: '',
      city: '',
      zipCode: '',
      email: '',
      contactInfo: '',
      financing: '',
      services: '',
      message: '',
      modalOpen: false,
      isSubmitted: false
    }
  },
  props: {
      modal: {
        type: Boolean,
        default: true
      } 
    },

  methods: {
    openModal() {
      document.getElementById('modal').style.display = 'flex';
      this.modalOpen = true
      document.body.classList.add('modal-open')
    },
    hardCloseModal() {
      document.getElementById('modal').style.display = 'none';
      this.modalOpen = false
      document.body.classList.remove('modal-open')
    },
    closeModal() {
      this.isSubmitted = true;
      document.body.classList.remove('modal-open')
    },
    submitForm() {
      const params = {
        name: this.name,
        phone: this.phone,
        address: this.address,
        city: this.city,
        zipCode: this.zipCode,
        email: this.email,
        contactInfo: this.contactInfo,
        financing: this.financing,
        services: this.services,
        message: this.message,
      }
      emailjs.send('service_l441b6k', 'template_9p9zsw9', params, 'Z7v2-EiSB2rGdO74_')
        .then(function (response) {
          console.log('SUCCESS!', response.status, response.text);
          document.getElementById('form-data-collection').reset();
        }, function (error) {
          console.log('FAILED...', error);
        });

      this.closeModal()
    },
  }
}
</script>

<style scoped>
/* Contact button */
.contact-button {
  background-color: #8d0808; /* Use your brand color */
  color: white;
  padding: 15px 30px; /* Increase padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Add a transform transition */
  font-size: 1.2em; /* Increase font size */
  z-index: 2;
  }

.contact-button:hover {
  background-color: #6FACF9;
  transform: scale(1.05);
}

.contact-button:active {
  transform: translateY(2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

/* Modal */
.modal {
  display: none;
  position: fixed;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modal-open {
  overflow: hidden;

  @media screen and (max-height: 768px) {
    overflow: scroll;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 450px;
  background-color: #9a9a9a;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease-in-out;
}

.modal-content:hover {
  transform: scale(1.05);
}

/* Form */
#form-data-collection {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-bottom: 2px solid black;
}

.form-groups {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 10px;
  margin-bottom: 10px;
}

.form-group {
  position: relative;
  display: flex;
  column-gap: 10px;
}

.form-groups input {
  height: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.5);
}


.form-groups input:focus {
  height: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.Selections {
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  width: 80%;
}

#preferences, #services {
  height: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 100%;
  border-radius: 5px;
}

.form-group input {
  padding-top: 12px;
  border-radius: 5px;
}

.form-group input:focus ~ .floating-label,
.form-group input:not(:focus):valid ~ .floating-label {
  font-size: 12px;
  opacity: 1;
  transform: translate(-5px, -15px); /* Adjust as needed */
}

.form-group .floating-label {
  position: absolute;
  color: black;
  pointer-events: none;
  left: 20px;
  top: 18px;
  transition: 0.2s ease all;
  height: 10px;
  padding-bottom: 15px;
}

/* Close button */
.close {
  position: absolute;
  color: #950d0f;
  top: 0px;
  right: 10px;
  font-size: 34px;
  font-weight: bold;
  cursor: pointer;
}

/* Request button */
.Request {
  width: 90%;
  height: 50px;
  background: steelblue;
  display: flex;
  font-size: 19px;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.Request:hover {
  background-color: #6FACF9;
}

.Request:active {
  transform: translateY(2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

/* Selector label */
.selector-label {
  text-wrap: balance;
  font-size: 20px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  color: black;
}

/* Contact info */
.bottom-content {
  padding-top: 15px;
  color: black;
  background-color: steelblue;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  font-family: 'Roboto', sans-serif;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.contact-us-title{
  text-align: center;
  color: black;
  font-size: 23px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 17px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0px;
}

.contact-info a {
  color: black;
}

.contact-info a:hover {
  color: #6FACF9;
}

.contact-info p {
  margin-top: 0px;
}

.contact-info h2 {
  margin: unset;
}
.contact-info {
  list-style-type: none;
}



/* Option */

</style>