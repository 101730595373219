<template>
    <div class="photo-card">
      <div class="image">
        <img class="Photo" :src="Services.imageSrc" alt="Image">
        <div class="text">
          <h2>{{ Services.title }}</h2>
        </div>
      </div>

      </div>
  </template>
  
  <script>
  export default {
    name: 'PhotoCard',
    props: {
    Services: {
            type: Object,
            required: true
    }
    },
  }
  </script>
  
  <style>

  
  .image {
    height: 25em;
    width: 25em;
    display: flex;
    flex-direction: column;

  }
  .Photo {
    height: 300px;
    width: 400px;
    object-fit: cover;
    object-position: center;
  }
  </style>