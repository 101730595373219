<template>
  <nav>
    <div v-if="isMobile" class="Header">
      <PageHeader 
      title="FREE Competitive Estimates!" 
      titleTwo='Ask About our 50 Year Warranty!'
      />
    </div>
  <div class="mobileContent">
    <div @click="showMenu = !showMenu" class="hamburger">
      <span :class="{ rotate: showMenu }"></span>
      <span :class="{ translate: showMenu }"></span>
      <span :class="{ rotateNeg: showMenu }"></span>
    </div>

    <div v-if="!isMobile" class="freeQuotes">
        <p>Free Competitive Estimates! </p>
        <p class="WarrantyMsg">Ask About our 50 Year Warranty! </p>
    </div>

    <div class="Logo-Container">
      <router-link to="/" @click="showMenu = false">
        <img class="Homes-Logo" :src="Logo" alt="homes roofing logo">
      </router-link>
    </div>

    <div v-if="!isMobile" class="freeQuotes">
          <p>Call Us Today! <a href="tel:269-262-5055">269-262-5055</a></p>
          <p class="financing">Now offering Financing on purchases over $3000</p>
    </div>
    
    <div class="Socials">
      <a href="https://www.facebook.com/profile.php?id=100091999461722" target="_blank"><img :src="Facebook" alt="Facebook"></a>

    </div>

  </div>
  </nav>
  
  <!-- Mobile Menu -->
  <div v-if="showMenu" class="mobile-menu">
    <div class="break"></div>
    <div class="mobileMenuContainer">
      <div class="links__section">
        <div class="links">
          <router-link to="/" @click="showMenu = false">Home</router-link>
          <!-- <router-link to="/about" @click="showMenu = false">About Us</router-link> -->
        </div>

        <div class="gallery">
          <h2 class="gallery__title">Photo Gallery</h2>
            <router-link to="/roofing-projects" @click="showMenu = false">Roofing</router-link>
            <router-link to="/siding-services" @click="showMenu = false">Siding</router-link>
            <router-link to="/gutter-services" @click="showMenu = false">Gutters</router-link>
        </div>
      </div>
      <div class="footer">
        <PageFooter/>
      </div>
      </div>
      


  </div>

</template>
  
  <script>
 import PageFooter from '@/components/PageFooter.vue';
 import PageHeader from '@/components/PageHeader.vue';
  export default {

    name: 'NavBar',
    metaInfo: {
        title: 'Roofing Services | Homes Roofing Co. | Niles, MI',
        meta: [
        {
            name: 'Homes Navigation Bar Component',
            content: 'Get high-quality roofing services from Homes Roofing in Niles, Michigan. Our experienced team provides expert installation, repair, and maintenance services for residential and commercial properties. Contact us today for a free estimate.',
        },
        ],
    },
    data() {
        return {
            Logo: require('@/assets/HomesRoofingLogo.jpg'),
            Facebook: require('@/assets/Logos/Facebook.png'),
            showMenu: false,
            isMobile: false,
        }
    },
    components: {
      PageFooter,
      PageHeader
    },
    created() {
        if(window.innerWidth <= 800){
          this.isMobile = true
        } else {
          this.isMobile = false
        }
      },
      
  }

  </script>
  
  <style scoped>

  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c3e50;
    color: white;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 3;
    opacity: 0.9;

    @media(max-width: 880px){
      .mobileContent{
        top: 80px;
      }
      
    }
  }
  .Header{
    display: flex;
  }
  .freeQuotes {
  color: white; /* White text */
  padding: 2px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration-line: underline; /* Underline the text */
  border-radius: 10px; /* Rounded corners */
  font-size: 1em; /* Increase font size */
  font-weight: bold; /* Bold font */
  transition: all 0.3s ease; /* Smooth transitions */



  a {
    color: #f1c40f; /* Yellow text */
    text-decoration: none; /* Remove underline */
  
  }
  
}

.financing {
  font-weight: bold;
  color: #f1c40f;
  text-decoration-line: underline;
}

.WarrantyMsg{
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  color: #f1c40f;
  padding: 2px;
  text-decoration-line: underline;
  border-radius: 10px;
  transition: all 0.3s ease;
}


.freeQuotes:hover {
  transform: scale(1.05); /* Slightly enlarge the div on hover */
}
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .Logo-Container {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100px;
  }
  .Homes-Logo {
    width: 100%;
    border-radius: 5px;
    outline-style: auto;
    outline-color: black;
  }
  .contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .phone {
    margin-right: 1rem;
    border: white 1px solid;
    border-radius: 5px;
    padding: 15px;
    background-color: #950d0f;
    outline: black 1px solid;
    border-color: #950d0f;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
  }
  .hamburger span {
    width: 100%;
    height: 3px;
    background-color: white;
    transition: all 0.3s ease;
    position: relative;
    transform-origin: 1px;
  }
  .mobileContent {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #2c3e50;
    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    justify-content: space-between;
  }
    .mobile-menu {
      display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #002655, #89c2fc);
    border: 1px solid #ccc;
    color: #bdbdbd;
    /* background-color: #2c3e50; */
    z-index: 99;
    transition: transform 0.3s ease-in-out;
    top: 88px;
    align-items: center;

    @media (max-width: 880px) {
      top: 167px;
    }
  }

    .links{
      width: 50%;
      height: 10em;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
      border-radius: 5px;

      a{
        padding: 10px;
      }
    }

  .mobile-menu a {
    color: white;
    font-size: 1.2rem;
  }
  .mobile-menu a:hover {
    color: #f1c40f;
  }
  .rotate {
    transform: rotate(45deg);
    
  }
  .rotateNeg {
    transform: rotate(-45deg);
  }
  .translate {
    opacity: 0;
  }
  .services{
    color: white;
    font-size: 2rem;
    text-decoration: underline;
    text-decoration-color: white;
  }

  .services:hover {
            color: #f1c40f;
      }

  .gallery{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-left: black solid 2px;
    position: relative;
    width: 50%;
    height: 10em;
  
  }
  .gallery__links{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border: black solid 1px;
    border-radius: 5px;
    position: relative;
    margin-top: 10px;
  }
  .gallery__title {
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.bottomMenu{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .contact-button{
      width: 50%
    }
}
.break{
 width: 100%;
 border-bottom: #8d0808 .25em solid;
}
.mobileMenuContainer{
  display: flex;
  width: 75%;
  height: 90%;
  flex-direction: column;
  justify-content: space-evenly;
}
.links__section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 5px;
  width: 100%;
  height: fit-content;
  padding-top: 1em;
  padding-bottom: 1em;;
  border: 1px solid #ccc;
  color: #bdbdbd
}

    .contactUs{
      display: flex;
      flex-direction: row;
      height: 10%;
      width: 50%;
      margin: 10px;
      align-items: center;
      justify-content: space-around;
      }

  </style>