<template>
    <div class="credentialsSection">
        <div class="credentials" v-for="credential in credentials" :key="credential.id">
        <div class="credentialImage">
            <img :src="credential.image" alt="credential.name"/>
            <div class="credentialName">{{ credential.name }}</div>
        </div>
 </div>
    </div>

</template>


<script>
export default {
    name: 'MyCredentials',
    data() {
        return {
            credentials: [
                {
                    id: 1,
                    name: 'Master Craftsman',
                    image: require('@/assets/Badges/MasterCraftsman.png'),
                },
                {
                    id: 2,
                    name: 'Shingle Master Certified',
                    image: require('@/assets/Badges/ShingleMaster.png'),
                },
            ],
        };
    },
    
    }
</script>

<style scoped>

.credentialsSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.credentialImage img {
    width: 150px; /* Adjust as needed */
    height: 150px; /* Adjust as needed */
    object-fit: cover;
}
.credentialName {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    color: white;
    padding-bottom: 10px;
}
</style>