<template>
    <div class="footer">
        <div class="payment-methods">
            <div class="payment-icons">
                <img src="../assets/Logos/Visa.png" alt="Visa Credit Card Logo">
                <img src="../assets/Logos/MasterCard.png" alt="MasterCard Credit Card Logo">
                <img src="../assets/Logos/Discover.png" alt="Disocover Credit Card Logo">
                <img src="../assets/Logos/Amex.png" alt="AMEX Credit Card Logo">
            </div>  
            <p class="CCDisclaimer">
                We accept all major credit cards.
            </p>
        </div>
        <div class="contact-info">
            <p>H.O.M.E.S. Roofing Co LLC</p>
            <p>Niles, MI, United States, 49120</p>

            <a href="https://www.facebook.com/profile.php?id=100091999461722" target="_blank">
                <p class="footerFacebook"> 
                    Check us out on Facebook:&nbsp;   
                    <img :src="Facebook" alt="Facebook">
                </p>
            </a>


            <p class="footer__phone"> <a class="phone-button" href="tel:269-262-5055">269-262-5055</a></p>
           
     
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'PageFooter',
    // Your JavaScript code goes here
    data () {
        return {
            Facebook: require('@/assets/Logos/Facebook.png'),
        };
    },
}
</script>

<style scoped>

.payment-icons {
    display: flex;
    justify-content: space-between;

    img {
        width: 70px;
        height: auto; /* maintain aspect ratio */
        object-fit: contain; /* ensure the images fit within their box */
        border-radius: 5px; /* round the corners */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* add a shadow for depth */
        margin-right: 10px; /* add some space between the images */
    }
}
.footerFacebook{
    display: flex;
    align-items: center;
    justify-content: center; /* Center items horizontally */
    padding: 10px; /* Add some padding */
    background-color: #3b5998; /* Facebook's brand color */
    color: white; /* White text color */
    border-radius: 5px; /* Rounded corners */
    text-decoration: none; /* Remove underline */
    }
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-image: linear-gradient(to right, #002655, #89c2fc);
    color: #bdbdbd
}

.payment-methods {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.contact-info {
    text-align: center;

    a {
        text-decoration: none;
    }
}


/* Media query for tablet and larger screens */
@media (min-width: 768px) {
    .footer {
        flex-direction: row;
        justify-content: space-around;
        display: flex;
    }

    .payment-methods {
        margin-bottom: 0;
        flex-direction: column;
    }



    .contact-info {
        text-align: left;
    }
}

   /* specific styling for Mobile */
   .contact-info {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;

        a {
            color: #bdbdbd;

            &:hover {
                text-decoration: underline;
                color: #002655;
            }
        }
   }
   .phone-button {
  display: inline-block;
  padding: 12px 30px;
  background-color: #8d0808;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.phone-button:hover {
  background-color: #0056b3;
}

    
</style>