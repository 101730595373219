<template>
    <div class="licensed-insured">
        <h1 class="licensed-insured__title">
            <b class="HomesText">H.O.M.E.S. Roofing LLC </b>is a fully licensed and insured roofing company based out of Niles & Serving Southwest Michigan.
        </h1>
        <p class="licensed-insured__content">
            Licensing: We are licensed by the state of Michigan as a roofing contractor with license number 252400025 . You can verify our license status at <a href="https://aca-prod.accela.com/LARA/GeneralProperty/LicenseeDetail.aspx?LicenseeNumber=252400025&LicenseeType=Residential+Builder+M+and+A+Individual"> LARA</a>.<br>
            Insurance: We carry One million dollars in general liability insurance to protect our customers and subcontractors. We are happy to provide you with certificates of insurance upon request.<br>
            In addition to being licensed and insured, we are also:<br>
            - Committed to providing our customers with the highest quality service and workmanship with warranties on all work performed.<br>
        </p>
    </div>
</template>

<style scoped>
.licensed-insured {
    background-image: linear-gradient(to right, #002655, #89c2fc);
    color: #f0f0f0;
    padding: 20px;
    border-radius: 0; /* remove rounded corners for a more "edgy" look */
    text-align: center;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.75); /* add a shadow for depth */
}

.licensed-insured__title {
    font-weight: bold;
    font-size: 28px; /* increase font size for more impact */
    margin-bottom: 20px;
    text-transform: uppercase; /* make the title uppercase for a more "edgy" look */
    letter-spacing: 2px; /* increase letter spacing for a more "edgy" look */
}

.licensed-insured__content {
    font-size: 24px;
    line-height: 1.5;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5); /* add a text shadow for more depth */
}

.HomesText {
    font-size: 1.2em; /* Increase font size */
    font-weight: bold; /* Make text bold */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add a text shadow */
}
</style>

<script>
export default {
    name: 'LicensedInsured'
}
</script>
