<template>
    <div class="RepairsPage">
      <div class="PhotoCarousel">
      <PhotoCarousel :images="Images"/>
      </div>
  
    </div>
    <PageFooter/>
    </template>
    
    <script>
    import PhotoCarousel from '@/components/PhotoCarousel.vue'
    import PageFooter from '@/components/PageFooter.vue'
    
  
    export default {
      metaInfo: {
          title: 'Roofing Services | Homes Roofing Co. | Niles, MI',
          meta: [
          {
              name: 'Homes Roofing Example Page',
              content: 'Get high-quality roofing services from Homes Roofing in Niles, Michigan. Our experienced team provides expert installation, repair, and maintenance services for residential and commercial properties. Contact us today for a free estimate.',
          },
          ],
      },
      data(){
          return{
            Images: []
          }
      },
      components: {
        PhotoCarousel,
        PageFooter
      },
      created() {
      const context = require.context('@/assets/Repairs', false, /\.(png|jpe?g|svg)$/);
      this.Images = context.keys().map(context).map((img, index) => {
        return {
          src: img,
          alt: `Roofing Project Photo #${index + 1}`
        }
      });
    }
      
    }
    </script>
  
  <style>
    .RepairsPage {
        
      background-color: #212121;
      display: flex;
      flex-direction: column;
      margin-top: 88px;
      
    }
    .PhotoCarousel {
      display: flex;
      justify-content: center;
      margin: 15px;
    }
    @media (max-width: 980px){
          .PhotoCarousel img {
              object-fit: contain;
        }
      }
      /* Images shoiuld be the same size */
  
  
 </style>