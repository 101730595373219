<template>
  <div id="app">
    <div class="overlay">
<!-- Add div above NAvBAr - Free Estimates - 50 YEar Warranty  -->
      <NavBar />
      <router-view></router-view>
    </div>
  </div>

</template>

<script>
import NavBar from './components/NavBar.vue';
export default {
  metaInfo: {
        title: 'Roofing Services | Homes Roofing Co. | Niles, MI',
        meta: [
        {
            name: 'Homes App Page',
            content: 'Get high-quality roofing services from Homes Roofing in Niles, Michigan. Our experienced team provides expert installation, repair, and maintenance services for residential and commercial properties. Contact us today for a free estimate.',
        },
        ],
    },
  name: 'App',
  components: {
    NavBar
}
}

</script>

<style>
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
}
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

body {
  margin: 0;
}
*, *:before, *:after {
    box-sizing: border-box;
}



</style>
