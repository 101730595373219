<template>
    
    <div class="HeaderPage">
            <div class="titles">
            <h1 class="title">{{ title }}</h1>
            <h1 class="titleTwo">{{ titleTwo }}</h1>
            <button class="callUs">
            <img src="../assets/phoneIcon.png" alt="Phone" class="phoneIcon" />
            <a class="callUsToday" href="tel:269-262-5055"> Call Us Today!</a> 
            </button>
        
        </div>

        <div class="break"></div>
    </div>


</template>

<script>
export default {
    name: 'PageHeader',
    props: {
        title: {
            type: String,
            required: true
        },
        titleTwo: {
            type: String,
            default: ''
        },
    }
}
</script>

<style scoped>

.background{
    display: flex;
    flex-direction: row;

}
.titles {
    background-color: #2c3e50;
    border-bottom: #212121 1px solid;
    position: fixed;
    top: 0;
    height: 80px;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}
.title {
    color: #f1c40f;
    font-size: 14px;
    text-decoration: underline;
    width: 30%;
}
.titleTwo {
    color: #f1c40f;
    font-size: 14px;
    text-decoration: underline;
    width: 30%;
}

.break{
 width: 100%;
 border-bottom: #212121 .25em solid;
}
.callUs {
    background-color: #8d0808;
    color: white;
    height: 40px;
    position: fixed;
    right: 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    margin-right: 5px;
    width: 35%;
    justify-content: space-evenly;

    a {
        color:white;
        text-decoration: none;
        font-weight: bolder;
    }

    @media (max-width: 550px) {
        padding: 2px 2px; /* Padding around the text */
        font-size: 10px;
    }
}
.phoneIcon {
    width: 30px;
    height: 30px;
}

.callUs:hover {
    background-color: #ff5500; /* Darker orange background on hover */
}

</style>

